
        @import "@infotrack/infotrackgo.web.core/styles/partials/_variables.scss";
        @import "@infotrack/infotrackgo.web.core/styles/partials/_mediaqueries.scss";
        @import "@infotrack/infotrackgo.web.core/styles/fonts.scss";
        @import "@infotrack/infotrackgo.web.core/styles/layout.scss";
        
.itg-snackbar {
    z-index: 9999999999;

    &__close-btn {
        color: $itg-black;
    }

    &__message {
        color: $itg-black;
    }

    &__alert {
        background-color: $itg-white;
        &__title {
            font-weight: bold;
        }
    }
}
